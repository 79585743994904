<template>
  <aside>
    <header>
      <h2>New Competition</h2>
      <button class="close" @click="close">
        <span class="sr-only">Close modal window</span>
      </button>
    </header>

    <main>
      <div class="setting-row">
        <div class="title">
          <h4>Competition name</h4>
        </div>
        <div class="fields">
          <input
            type="text"
            class="form-control"
            :class="{ 'is-invalid': $v.name.$error }"
            v-model="$v.name.$model"
          />
          <div class="invalid-feedback">Competition name is required.</div>
        </div>
      </div>

      <div class="setting-row award-selector">
        <div class="title">
          <h4>Award</h4>
          <p>The award that is given to the winner(s) of the competition.</p>
        </div>
        <div class="fields">
          <v-select
            v-model="$v.award.$model"
            :class="{ 'is-invalid': $v.award.$error }"
            :options="available_awards"
            label="name"
            :clearable="false"
            placeholder="Select an award..."
            :reduce="(opt) => opt.id"
          >
            <template #option="option">
              {{ option.name }}
              <span class="point-amount">{{
                $formatPoints(option.points)
              }}</span>
            </template>
          </v-select>
          <div class="invalid-feedback">An award is required.</div>
        </div>
      </div>

      <div class="setting-row award-selector">
        <div class="title">
          <h4>Tagged Value (optional)</h4>
          <p>Only include shoutouts with this tagged value.</p>
        </div>
        <div class="fields">
          <select class="form-control" v-model="tagged_value">
            <option value="ALL">Any value</option>

            <option
              v-for="{ text, hash } in team.shoutout_values"
              :value="hash"
              :key="`shoutoutValue_${hash}`"
              >{{ text }}</option
            >
          </select>
        </div>
      </div>

      <div class="setting-row">
        <div class="title">
          <h4>Recurring</h4>
          <p>
            Whether the competition should end on a specific date, or
            automatically reset.
          </p>
        </div>
        <div class="fields">
          <div
            class="btn-group btn-group-sm btn-group-toggle"
            data-toggle="buttons"
          >
            <label class="btn btn-outline-secondary active">
              <input
                type="radio"
                name="interval"
                value="END_DATE"
                v-model="interval"
              />
              Not recurring
            </label>
            <label class="btn btn-outline-secondary">
              <input
                type="radio"
                name="interval"
                value="MONTH"
                v-model="interval"
              />
              Monthly
            </label>
          </div>
        </div>
      </div>

      <div class="setting-row">
        <div class="title">
          <h4>Start date</h4>
          <p>Date when the competition begins.</p>
        </div>
        <div class="fields">
          <date-range-picker
            :class="{ 'is-invalid': $v.start_date.$error }"
            :single-date-picker="true"
            :min-date="$today.toJSDate()"
            :ranges="false"
            v-model="$v.start_date.$model"
          >
            <template #input="{startDate}">
              <span class="placeholder" v-if="startDate === null"
                >Select a date...</span
              >
              <span v-else
                ><i class="fas fa-calendar"></i>
                {{ startDate.toLocaleDateString() }}
                <a href="#" @click.prevent.stop="start_date.startDate = null"
                  ><i class="fas fa-times-circle"></i></a
              ></span>
            </template>
          </date-range-picker>
          <div class="invalid-feedback">A start date is required.</div>
        </div>
      </div>

      <div class="setting-row" v-if="interval === 'END_DATE'">
        <div class="title">
          <h4>End date</h4>
          <p>Date when the competition ends.</p>
        </div>
        <div class="fields">
          <date-range-picker
            :class="{ 'is-invalid': $v.end_date.$error }"
            :single-date-picker="true"
            :min-date="start_date.startDate || $today.toJSDate()"
            :ranges="false"
            v-model="$v.end_date.$model"
          >
            <template #input="{startDate}">
              <span class="placeholder" v-if="startDate === null"
                >Select a date...</span
              >
              <span v-else
                ><i class="fas fa-calendar"></i>
                {{ startDate.toLocaleDateString() }}
                <a href="#" @click.prevent.stop="end_date.startDate = null"
                  ><i class="fas fa-times-circle"></i></a
              ></span>
            </template>
          </date-range-picker>
          <div class="invalid-feedback">An end date is required.</div>
        </div>
      </div>

      <div class="setting-row">
        <div class="title">
          <h4>Start/end times</h4>
          <p>Time of day the competition should start and end.</p>
        </div>
        <div class="fields">
          <div class="d-flex">
            <div>
              <label for="startHourSelector">Start hour</label>
              <div class="d-flex">
                <select
                  name="startHourSelector"
                  id="startHourSelector"
                  v-model="start_time.hour"
                  class="form-control"
                >
                  <option
                    :value="hour"
                    v-for="hour in 12"
                    :key="`startHour_${hour}`"
                    >{{ hour }}</option
                  >
                </select>
                <select
                  name="startHourAMPM"
                  id="startHourAMPM"
                  v-model="start_time.ampm"
                  class="form-control"
                >
                  <option value="AM">AM</option>
                  <option value="PM">PM</option>
                </select>
              </div>
            </div>
            <div class="ml-3">
              <label for="endHourSelector">End hour</label>
              <div class="d-flex">
                <select
                  name="endHourSelector"
                  id="endHourSelector"
                  v-model="end_time.hour"
                  class="form-control"
                >
                  <option
                    :value="hour"
                    v-for="hour in 12"
                    :key="`startHour_${hour}`"
                    >{{ hour }}</option
                  >
                </select>
                <select
                  name="endHourAMPM"
                  id="endHourAMPM"
                  v-model="end_time.ampm"
                  class="form-control"
                >
                  <option value="AM">AM</option>
                  <option value="PM">PM</option>
                </select>
              </div>
            </div>
          </div>

          <div class="help-text">All times are in {{ timezone_name }}</div>
        </div>
      </div>

      <div class="setting-row" v-if="type === 'PVP'">
        <div class="title">
          <h4>Participating channel</h4>
          <p>
            Users in this channel will compete against each other in this
            competition.
          </p>
        </div>
        <div class="fields">
          <v-select
            v-model="$v.group.$model"
            :class="{ 'is-invalid': $v.group.$error }"
            :options="groups"
            label="title"
            :clearable="false"
            placeholder="Select a channel..."
            :reduce="(opt) => opt.id"
          />
          <div class="invalid-feedback">A channel is required.</div>
        </div>
      </div>
    </main>

    <footer>
      <button
        class="btn btn-green btn-sm"
        :class="{ loading: loading }"
        @click="createCompetition"
      >
        Create competition
      </button>
    </footer>
  </aside>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker';
import { required, requiredIf, minLength } from 'vuelidate/lib/validators';

export default {
  components: {
    DateRangePicker,
  },
  computed: {
    groups() {
      return this.$store.state.groups;
    },
    team() {
      return this.$store.state.team;
    },
    available_awards() {
      if (this.$store.state.awards === null) return null;
      return _.filter(this.$store.state.awards, (a) => !a.is_bot);
    },
    loading() {
      return this.$store.state.loading;
    },
    timezone_name() {
      return this.$DateTime
        .local()
        .setZone(this.team.timezone)
        .toFormat('ZZZZZ');
    },
  },
  data() {
    return {
      name: '',
      start_date: {
        startDate: null,
      },
      end_date: {
        startDate: null,
      },
      interval: 'END_DATE',
      type: 'PVP',
      group: null,
      tvt_groups: [],
      metric: 'SHOUTOUT_COUNT',
      tagged_value: 'ALL',
      award: null,
      start_time: {
        hour: 1,
        ampm: 'AM',
      },
      end_time: {
        hour: 1,
        ampm: 'AM',
      },
    };
  },
  validations: {
    name: {
      required,
    },
    group: {
      required,
    },
    award: {
      required,
    },
    start_date: {
      required: function(model) {
        return model.startDate !== null;
      },
    },
    end_date: {
      required: function(model) {
        if (this.interval !== 'END_DATE') return true;
        return this.interval === 'END_DATE' && model.startDate !== null;
      },
    },
  },
  methods: {
    close() {
      this.$emit('done');
    },
    async createCompetition() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.$store.commit('loading', true);

      const resp = await this.$api.Competitions.create({
        title: this.name,
        interval: this.interval,
        user_group: this.group,
        tagged_value: this.tagged_value,
        start_time: this.$DateTime
          .fromJSDate(this.start_date.startDate)
          .set({
            hour:
              this.start_time.hour + (this.start_time.ampm === 'PM' ? 12 : 0),
          })
          .toJSDate(),
        end_time:
          this.interval !== 'END_DATE'
            ? this.end_time.hour + (this.end_time.ampm === 'PM' ? 12 : 0)
            : this.$DateTime
                .fromJSDate(this.end_date.startDate)
                .set({
                  hour:
                    this.end_time.hour + (this.end_time.ampm === 'PM' ? 12 : 0),
                })
                .toJSDate(),
        award_id: this.award,
      });
      this.$toast.success('Competition created successfully.');
      this.$store.commit('loading', false);

      this.$emit('done', 'COMPETITION_CREATED');
    },
  },
};
</script>

<style lang="scss" scoped>
.vue-daterange-picker {
  width: 180px;

  .reportrange-text {
    > .placeholder {
      color: $input-placeholder-color;
    }

    > span {
      line-height: 1.7em;
      display: block;
      position: relative;

      > i {
        color: $input-placeholder-color;
        font-size: 0.8em;
        margin: 0 5px 0 0;
        vertical-align: 1px;
      }

      > a {
        display: block;
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -7px;
        height: 14px;
        width: 14px;
        font-size: 13px;
        line-height: 14px;
        color: $input-placeholder-color;
        text-decoration: none;
        opacity: 0;
        transform: translateX(10px);
        transition: opacity 0.2s $curve, transform 0.2s $curve;

        &:hover {
          color: darken($input-placeholder-color, 5%);
        }
      }
    }

    &:hover {
      > span {
        > a {
          transform: none;
          opacity: 1;
        }
      }
    }
  }
}

.leaderboard-metric {
  .shoutout-tags {
    display: flex;
    margin: 15px 0 0;
    align-items: center;

    label {
      font-weight: 700;
      font-size: 12px;
      color: $muted-text;
      white-space: nowrap;
      margin: 0 7px 0 0;
    }
  }
}

.award-selector {
  .point-amount {
    background: $green;
    color: #fff;
    border-radius: 3px;
    line-height: 10px;
    font-size: 10px;
    display: inline-block;
    margin: 0 0 0 10px;
    padding: 2px 4px;
    vertical-align: 1px;
  }
}
</style>
